<script lang="ts">
    import { Icon, CheckCircle } from 'svelte-hero-icons';

    // interface User {
    //     username: string;
    //     name: string;
    //     avatar: string;
    //     email_verified_at?: string | null;
    //     birthdate?: string | null;
    //     verification?: {
    //         status: string;
    //     };
    //     bio?: string;
    // }
    export let users;
    export let theme;
</script>

<!--  class="font-bold text-{{ Cookie::get('app_theme') == null ? (getSetting('site.default_user_theme') == 'dark' ? 'white' : 'dark') : (Cookie::get('app_theme') == 'dark' ? 'white' : 'dark') }} mr-2 d-flex align-items-center"> -->

{#each users as user}
    <div class="user-search-box-item mb-4">
        <div class="flex px-4">
            <div class="pr-0">
                <img src="{user.avatar}" alt="{user.username}" class="h-20 w-20 rounded-full shadow" />
            </div>
            <div class="ml-2 flex-1">
                <div class="flex items-center justify-between">
                    <div class="user-search-box-info truncate">
                        <div class="m-0 flex items-center truncate text-lg">
                            <a href="/{user.username}" class="font-bold text-{theme} mr-2 flex items-center">
                                {user.name}
                            </a>
                            {#if user.email_verified_at && user.birthdate && user.verification && user.verification.status == 'verified'}
                                <span class="ml-2" data-tooltip="Verified user">
                                    <Icon src="{CheckCircle}" class="h-4 w-4 text-bmn-500" />
                                </span>
                            {/if}
                        </div>
                        <div class="m-0 truncate text-sm text-gray-500">
                            <a href="/{user.username}" class="text-gray-500">&commat;{user.username}</a>
                        </div>
                    </div>
                    <div class="flex items-center">
                        <a
                            role="button"
                            class="rounded-full border border-bmn-500 px-4 py-1 text-sm text-bmn-500 transition hover:bg-bmn-500 hover:text-white"
                            href="/{user.username}"
                        >
                            View
                        </a>
                    </div>
                </div>

                <div class="mt-1 text-gray-700">
                    {user.bio ? user.bio : 'No description available.'}
                </div>
            </div>
        </div>
    </div>
{/each}
